import appsApi from "./admin.api";
import utils from "@/helpers/utilidades";

export default {
    /**
     * Consulta y filtra la lista de aplicaciones
     * @param modulo
     * @returns {Promise<AxiosResponse<any>>}
     */
    index(modulo) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicaciones/modulo/${modulo}/permisos`));
    },

    crear(modulo,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicaciones/modulo/${modulo}/permisos`, payload)
    },

    editar(modulo, permiso, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicaciones/modulo/${modulo}/permisos/${permiso}`, payload)
    },

    eliminar(modulo, permiso) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicaciones/modulo/${modulo}/permisos/${permiso}`)
    },

}
