<template>

  <div>

    <div class="container">
      <div class="row mb-3">
        <div class="col-sm-auto">
          <button class="btn btn-sm btn-info" @click="cargar_roles"> <i class='fa fa-refresh'></i> Recargar roles</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('bpb_apps_admin','roles_crear')">
          <button  class="btn btn-sm btn-primary" @click="open_formulario"><i class='fa fa-plus-circle'></i> Nuevo rol</button>
        </div>
      </div>
      <table class="table table-sm table-striped">
        <thead>
        <tr class="bg-dark">
          <th scope="col"></th>
          <th scope="col">Nombre</th>
          <th scope="col">Descripción</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!roles.length"><td colspan="4" class="text-secondary text-center"> No se encontraron roles para mostrar.</td></tr>
        <tr v-for="item_rol in roles">
          <td class="text-center">
            <button type="button" class="btn btn-sm btn-primary rounded-circle fa fa-edit" @click="editar_rol(item_rol)">
            </button>
            <button type="button" class="btn btn-sm btn-dark rounded-circle  ml-2" @click="pre_eliminar_rol(item_rol)"><i class="fa fa-trash"></i></button>
          </td>
          <td>{{item_rol.rol.nombre}}</td>
          <td>{{item_rol.rol.descripcion}}</td>
        </tr>
        </tbody>
      </table>
    </div>



    <Modal v-if="formulario" :options="{width: '50vw',type:'primary'}" @close="cerrar_modal_rol">
      <div class="title"> {{!rol.id ? 'Crear' : 'Editar'}} Rol</div>

      <div class="body"  v-if="cliente_id && !modulos.length">
        <div class="alert alert-warning text-center" role="alert">
          No se encontraron módulos agregados.
        </div>
      </div>

      <div class="body"  v-else>
        <div class="row form-group">
          <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
          <div class="col-sm-10"><input v-model="rol.nombre" type="text" name="nombre_rol" id="nombre_rol" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
          <div class="col-sm-10"><input v-model="rol.descripcion" type="text" name="descripcion_rol" id="descripcion_rol" class="form-control"></div>
        </div>

        <div class="row form-group" v-if="cliente_id">
          <label for="descripcion" class="col-form-label col-sm-2">Módulo</label>
          <div class="col-sm-10">
            <select class="form-control" name="nvo_permiso_rol_permiso_id" id="nvo_permiso_rol_permiso_id"  v-model="rol_cliente_modulo_id" :disabled="!modulos.length || (rol.id && modulo_id)" @change="cargar_permisos">
              <option :value=modulo.id v-for="modulo in modulos">{{modulo.nombre}}</option>
            </select>
          </div>
        </div>

        <div class="mb-3 clearfix">
          <h5 class="d-block"> Permisos ({{permisosSeleccionados.length}})</h5>
          <div class="text-danger" v-if="validacion_seleccion_permisos">
            * Debe seleccionar al menos un permiso.
          </div>

          <div class="body"  v-if="!cliente_id && !permisos.length">
            <div class="alert alert-warning text-center" role="alert">
              No se encontraron permisos agregados al módulo, primero deber agregarlos en la sección de permisos.
            </div>
          </div>

          <!--
          <ul id="lista-permisos-form" class="mt-2" v-else>
            <li v-for="permiso in permisos">
              <input class="form-check-input" type="checkbox" :id="'permiso-'+permiso.id" :value="permiso.id" v-model="rol.permisos">
              {{ permiso.permiso }}
            </li>
          </ul>
          -->

          <table class="table table-sm table-striped">
            <thead>
            <tr class="bg-info">
              <th>Seleccione los permisos del rol:</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th colspan="3">
                <div class="form-row align-items-center">
                  <div class="col-12 mb-sm-2">
                    <span class="text-success  d-block">Agregar permiso a rol</span>
                    <small class="text-secondary d-block" v-if="!cliente_id">Seleccione el permiso y los datos del alcance del permiso, agregalo presionando el botón de confirmación.</small>
                    <small class="text-secondary d-block" v-else>Seleccione el módulo en la parte de arriba, después el permiso y los datos del alcance del permiso, agregalo presionando el botón de confirmación.</small>
                  </div>

                  <div class="col">
                    <label class="text-secondary">Permiso</label>
                    <select class="form-control" name="nvo_permiso_rol_permiso_id" id="nvo_permiso_rol_permiso_id" v-model="nvo_permiso_rol.permiso" :disabled="!permisos_select.length">
                      <option :value=permiso v-for="permiso in permisos_select">{{permiso.permiso}}</option>
                    </select>
                  </div>

                  <div class="col">
                    <label class="text-secondary">Acceso</label>
                    <select v-model="nvo_permiso_rol.acceso" name="nvo_permiso_rol_acceso" id="nvo_permiso_rol_acceso" class="form-control" :disabled="!permisos_select.length">
                      <option value="1">Permitido</option>
                      <option value="0">Denegado</option>
                    </select>
                  </div>

                  <div class="col">
                    <label class="text-secondary">Visibilidad(?)</label>
                    <select v-model="nvo_permiso_rol.ver_aplicacion" name="nvo_permiso_rol_ver_aplicacion" id="nvo_permiso_rol_ver_aplicacion" class="form-control" :disabled="!permisos_select.length">
                      <option value="1">Visible</option>
                      <option value="0">Oculto</option>
                    </select>
                  </div>
                  <div class="col col-auto">
                    <br>
                    <button type="button" class="btn btn-sm rounded-circle btn-primary" @click="agregar_permiso" :disabled="!permisos_select.length"><i class="fa fa-check"></i></button>
                    <button type="button" class="btn btn-link" @click="reset_nvo_permiso" :disabled="!permisos_select.length">Cancelar</button>
                  </div>
                </div>
              </th>
            </tr>

            <tr v-if="!permisosSeleccionados.length"><td colspan="3" class="text-secondary text-center"> Sin permisos agregados.</td></tr>

            <tr v-for="(item, index) in permisosSeleccionados" class="row-permiso">
                <th colspan="3">
                  <div class="form-row align-items-center">
                    <div class="col-12 mb-sm-2" v-if="index_editar === index">
                      <span class="text-primary  d-block">Editar permiso</span>
                      <small class="text-secondary d-block">Editar datos del permiso, confirma tus cambios presionando el botón de confirmación.</small>
                    </div>

                    <div class="col">
                      {{item.permiso.permiso}}
                    </div>

                    <div class="col">
                      <select v-model="item.acceso" :class="index !== index_editar && 'permiso_rol_list'" name="permiso_rol_acceso" id="permiso_rol_acceso" class="form-control"  :disabled="index_editar !== index">
                        <option value="1">Permitido</option>
                        <option value="0">Denegado</option>
                      </select>
                    </div>

                    <div class="col">
                      <select v-model="item.ver_aplicacion" :class="index !== index_editar && 'permiso_rol_list'" name="permiso_rol_ver_aplicacion" id="permiso_rol_ver_aplicacion" class="form-control"  :disabled="index_editar !== index">
                        <option value="1">Visible</option>
                        <option value="0">Oculto</option>
                      </select>
                    </div>
                    <div  class="col col-auto" v-if="index_editar === index">
                      <button type="button" class="btn btn-sm rounded-circle btn-info" @click="pre_editar_permiso"><i class="fa fa-check"></i></button>
                      <button type="button" class="btn btn-link" @click="cancelar_editar_permiso">Cancelar</button>
                    </div>

                    <div class="col col-auto" v-else>
                      <button type="button" class="btn btn-info btn-sm rounded-circle"
                              :class="index_editar !== null && index_editar !== index && 'text-grey'"
                              :disabled="index_editar !== null && index_editar !== index"
                              @click="seleccionar_editar(item, index)">
                        <i class="fa fa-edit"></i>
                      </button>

                      <button class="btn btn-sm btn-dark rounded-circle ml-2" :disabled="index_editar !== null && index_editar !== index" @click="pre_eliminar_permiso(item,index)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="guardar" :disabled="!rol.id && (!cliente_id && !permisos.length || cliente_id && !modulos.length)"><i class='fa fa-save mr-sm-1'></i>Guardar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal_rol"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="index_eliminar !== null" :options="{width: '30vw',type:'error'}" @close="cancelar_eliminar_permiso">
      <div class="title">Eliminar permiso</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el permiso: <b>{{ permiso_rol.permiso.permiso }}</b> del rol <b>{{rol.nombre}}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_permiso"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_eliminar_permiso">Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalEliminarRol" :options="{width: '30vw',type:'error'}" @close="cerrar_modal_rol">
      <div class="title">Eliminar rol</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el rol: <b>{{rol.nombre}}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="eliminar_rol"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cerrar_modal_rol"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalModuloSinPermisos" :options="{width: '30vw',type:'warning'}">
      <div class="title">Permisos de módulo</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            El módulo seleccionado no tiene permisos disponibles para asignar, seleccione uno nuevo o agregue mas permisos en el apartado de "Permisos" del módulo.<br/>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="modalModuloSinPermisos =  false">OK</button></div>
        </div>
      </div>
    </Modal>

  </div>

</template>

<script>

import Modal from '@/components/Modal'
import rolesApi from "@/apps/bpb_apps_admin/api/roles.api";
import permisosApi from "@/apps/bpb_apps_admin/api/permisos.api";
import modulosApi from "@/apps/bpb_apps_admin/api/modulos.api";
import clientesApi from "@/apps/bpb_apps_admin/api/clientes.api";
import Vue from "vue";

export default {
  name: "Roles",
  components: {Modal},
  data(){
    return {
      validacion_seleccion_permisos: false,
      formulario: false
      ,index_editar: null
      ,index_eliminar: null
      ,modalEliminarRol: false
      ,modalModuloSinPermisos: false
      ,roles: []
      ,permisos: []
      ,modulos: []
      ,permisosSeleccionados: []
      ,rol: {
        id: null,
        nombre: null,
        descripcion: null,
        tipo: null,
      }
      ,permiso_rol: {
        id: null,
        permiso: null,
        modulo: null,
        permiso_id: null,
        modulo_id: null,
        acceso: null,
        alcance: null,
        ver_aplicacion: null,
      }
      ,nvo_permiso_rol: {
        permiso: null,
        modulo: null,
        permiso_id: null,
        modulo_id: null,
        acceso: null,
        alcance: null,
        ver_aplicacion: null,
      }
      ,rol_cliente_modulo_id: null //ID DEL ROL DEL MÓDULO
      ,tipos_roles: {rol: 1, grupo:2}
    }
  },
  props: {
    aplicacion_id: null,//SOLO ES REQUERIDO PARA ROLES DE CLIENTES
    modulo_id: null,//SOLO ES REQUERIDO PARA ROLES DE MÓDULO
    cliente_id: null//SOLO ES REQUERIDO PARA ROLES DE CLIENTES
  },
  computed: {
    permisos_select(){
      return JSON.parse(JSON.stringify(this.permisos)).filter(item => !this.permisosSeleccionados.find( permiso => permiso.permiso_id === item.id));
    }
    ,validaciones_permiso_crear(){
      return {
        nvo_permiso_rol_permiso_id: 'required',
        nvo_permiso_rol_acceso: 'required',
        nvo_permiso_rol_alcance: 'required',
        nvo_permiso_rol_ver_aplicacion: 'required'
      }
    }
    ,validaciones_permiso_editar(){
      return {
        permiso_rol_acceso: 'required',
        permiso_rol_alcance: 'required',
        permiso_rol_ver_aplicacion: 'required'
      }
    }

    ,validaciones(){
      let validaciones  = {
        nombre_rol: !this.rol.nombre ? 'required' : 'min:5|max:255',
        descripcion_rol: !this.rol.descripcion ? 'required' : 'min:5|max:255',
        tipo_rol: 'required'
      };

      if (Object.values(this.nvo_permiso_rol).some(value => !!value)) {
        validaciones = {...validaciones, ...this.validaciones_permiso_crear}
      }

      if (this.index_editar !== null)
        validaciones = {...validaciones, ...this.validaciones_permiso_editar}

      return validaciones
    }

  },
  mounted() {

    this.cargar_roles()

  },
  methods: {

    async cargar_modulos(){
      let dataSource = await modulosApi.index(this.aplicacion_id)
      this.modulos = dataSource.data;
    }

    ,obtener_permiso_nombre(id){
      let permiso = this.permisos.find(item => item.id === id);
      return !this.permisos.length || !permiso ? '' : permiso.permiso
    },

     cerrar_modal_rol(){

      this.modalEliminarRol = false
      this.rol = {
        id: null,
        nombre: null,
        descripcion: null,
        tipo: null,
      }

      this.permisosSeleccionados = []
      this.formulario = false

       //Elimina valor de modulo_id que se asigna cuando el rol es del cliente
         this.rol_cliente_modulo_id = null

    }

    ,async cargar_roles(){
      let dataSource = await (!this.cliente_id ?  rolesApi.index(this.modulo_id) : clientesApi.index_roles(this.aplicacion_id, this.cliente_id))
      this.roles = dataSource.data;
    }

    ,async editar_rol(item){

      let rol = await (!this.cliente_id ? rolesApi.rol(item.modulo_id, item.id) : clientesApi.rol_cliente(this.aplicacion_id, this.cliente_id,item.id))
      this.rol = rol.data.rol

      this.rol_cliente_modulo_id = rol.data.modulo_id

      if (this.rol.roles_permisos)
        this.permisosSeleccionados = this.rol.roles_permisos;

      await this.open_formulario();
    }

    ,pre_eliminar_rol(item){
      this.rol = item.rol;
      this.modalEliminarRol = true;
    }

    ,async eliminar_rol(){
      await (!this.cliente_id ? rolesApi.eliminar(this.modulo_id, this.rol.id) : clientesApi.eliminar_rol(this.aplicacion_id, this.cliente_id, this.rol.id))
      this.$helper.showMessage('Eliminar rol','Rol eliminado exitosamente.', 'success', 'alert')
      this.cerrar_modal_rol()
      await this.cargar_roles()
      this.$emit('update')

    }

    ,async cargar_permisos(){
      const modulo_id = !this.cliente_id ? this.modulo_id : this.rol_cliente_modulo_id
      let dataSource = await permisosApi.index(modulo_id)
      this.permisos = dataSource.data;

      if (!this.permisos.length)
        this.$helper.showMessage('Permisos módulo','El módulo seleccionado no tiene permisos agregados.', 'warn', 'alert')

    }

    ,async open_formulario(){
      try {

        if (this.modulo_id || this.rol_cliente_modulo_id)
          await this.cargar_permisos()

        if (this.cliente_id)
          await this.cargar_modulos()

        this.formulario = true
      }catch (e) {
        this.$log.error('Error',e);
        this.$helper.showMessage('Formulario de rol','Ocurrió un error al cargar el formulario, recargue la página e intente de nuevo, si el problema persiste favor de reportarlo al área de IT.', 'error', 'alert', 20000)
      }
    }

    ,reset_permiso_seleccionado(){
      this.permiso_rol = {
        id: null,
        permiso: null,
        modulo: null,
        permiso_id: null,
        modulo_id: null,
        acceso: null,
        alcance: null,
        ver_aplicacion: null,
      }
    }
    ,reset_nvo_permiso(){
      this.nvo_permiso_rol =  {
        permiso: null,
        modulo: null,
        permiso_id: null,
        modulo_id: null,
        acceso: null,
        alcance: null,
        ver_aplicacion: null,
      }
    }

    ,seleccionar_editar(item, index){
      this.permiso_rol = Object.assign({},item);
      this.index_editar = index
    }

    ,cancelar_eliminar_permiso(){
      this.reset_permiso_seleccionado()
      this.index_eliminar = null
    }

    ,cancelar_editar_permiso() {
      this.permisosSeleccionados[this.index_editar] = this.permiso_rol
      this.index_editar = null
      this.reset_permiso_seleccionado()
    }

    ,async agregar_permiso(){
      let isValid = await this.$validator(this.validaciones_permiso_crear)
      if (isValid) {
        this.nvo_permiso_rol.modulo_id = !this.cliente_id ? this.modulo_id : this.rol_cliente_modulo_id ;
        this.permisosSeleccionados.push(this.nvo_permiso_rol)
        this.reset_nvo_permiso()

      }
    }

    ,pre_eliminar_permiso(item, index){
      this.permiso_rol = this.permisosSeleccionados[index];
      this.index_eliminar = index
    }


    ,async pre_editar_permiso(){
      let isValid = await this.$validator(this.validaciones_permiso_editar)
      if (isValid && this.index_editar !== null) {
        this.index_editar = null
        this.reset_permiso_seleccionado()
      }
    }
    ,async guardar(){

      this.validacion_seleccion_permisos = !this.permisosSeleccionados.length;
      let isValid = await this.$validator(this.validaciones)

      if (isValid && !this.validacion_seleccion_permisos) {

        await this.pre_editar_permiso()

        let payload = {...this.rol}



        if (!this.cliente_id) {
          if (!this.rol.id) {
            payload.tipo = this.tipos_roles.rol
            payload.modulo_id = this.modulo_id;
            const nuevo_rol = await rolesApi.crear(this.modulo_id, payload)
            this.rol.id = nuevo_rol.data.id;
          } else {
            await rolesApi.editar(this.modulo_id, this.rol.id, payload).then(() => {
              this.$helper.showMessage('Editar rol', 'Rol actualizado exitosamente.', 'success', 'alert')
            })
          }
        }else{

          let payload_rol_cliente = {...this.rol, modulo_id: this.rol_cliente_modulo_id}
          if (!this.rol.id) {
            payload_rol_cliente.tipo = this.tipos_roles.rol
            const nuevo_rol = await clientesApi.crear_rol(this.aplicacion_id, this.cliente_id, payload_rol_cliente)
            this.rol.id = nuevo_rol.data.id;
          } else {
            await clientesApi.editar_rol(this.aplicacion_id, this.cliente_id, this.rol.id, payload_rol_cliente).then(() => {
              this.$helper.showMessage('Editar rol', 'Rol actualizado exitosamente.', 'success', 'alert')
            })
          }

        }

        await this.guardarPermisos()
        await this.cargar_roles();
        this.$emit('update')
        this.cerrar_modal_rol()

      }
    }

    ,async guardarPermisos(){
      try {
        for (let index_permiso in this.permisosSeleccionados) {
          let item_permiso    = this.permisosSeleccionados[index_permiso];
          let permiso_payload = (({ permiso, modulo, ...data_permiso }) => data_permiso)(item_permiso);

          if (!permiso_payload.id) {
            //Alcance 1 = Todos
            permiso_payload.alcance = 1
            const nvo_permiso = await (!this.cliente_id ?  rolesApi.crear_permiso(this.rol.id, permiso_payload) : clientesApi.crear_permiso_rol(this.aplicacion_id,this.cliente_id,this.rol.id, permiso_payload))
            nvo_permiso.data.permiso = item_permiso.permiso
            this.$set(this.permisosSeleccionados,index_permiso, nvo_permiso.data)

          } else
            await (!this.cliente_id ? rolesApi.editar_permiso(this.rol.id,permiso_payload.id, permiso_payload) : clientesApi.editar_permiso_rol(this.aplicacion_id,this.cliente_id,this.rol.id, permiso_payload.id, permiso_payload))
        }
      }catch (e) {

        this.$log.error('Error',e);

        if (e.response &&  e.response.status !== 400)
          this.$helper.showMessage('Agregar permisos','Ocurrio un error al agregar los permisos al rol, favor de reportarlo al área de IT.', 'error', 'alert')
      }

    }




   /**
   * Eliminar permiso de listado, si era un permiso ya creado (con id) emitir update para actualizar listado de módulos
   * @returns {Promise<void>}
   */
  ,async eliminar_permiso(){



    const permiso_id = this.permiso_rol.id;
    let eliminado = false;

    if (!!permiso_id) {

      await (!this.cliente_id ? rolesApi.eliminar_permiso(this.permiso_rol.rol_id, this.permiso_rol.id) :
          clientesApi.eliminar_permiso_rol(this.aplicacion_id,this.cliente_id, this.rol.id, this.permiso_rol.id) ).then(() => {

            this.$helper.showMessage('Eliminar permiso de rol', 'Permiso eliminado exitosamente.', 'success', 'alert')
            eliminado = true;
      })
    }

    if ( (!!permiso_id && eliminado) || !permiso_id) {
      this.index_eliminar = null
      this.permisosSeleccionados.splice(this.index_eliminar, 1)
      this.reset_permiso_seleccionado()
      eliminado = true; // si !permiso_id confirma que si se guardarón cambios
    }

    if (!!permiso_id && eliminado)
      this.$emit('update')
      this.cerrar_modal_rol()

  }

            //nvo_permiso.data.modulo = item_permiso.modulo

  }
  ,watch: {
    'nvo_permiso_rol.modulo': {
      handler(newValue, oldValue) {
        if (newValue && newValue.id){
          this.nvo_permiso_rol.modulo_id = newValue.id
        }

        if (this.nvo_permiso_rol.modulo_id && !this.permisos_select.length){
          this.nvo_permiso_rol.modulo = null
          this.nvo_permiso_rol.modulo_id = null
          this.modalModuloSinPermisos =  true
        }

      }
    }
    ,'nvo_permiso_rol.permiso': {
      handler(newValue, oldValue) {
        if (newValue && newValue.id){
          this.nvo_permiso_rol.permiso_id = newValue.id
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
input.checkbox {
  display: inline-block;
}
.tab-pane{
  padding-top: 25px;
}

#lista-modulos-paquete{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

#lista-permisos-form{
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
}

.permiso_rol_list{
  background: none;
  border: none;
  appearance: none;
}
</style>
